import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private snackbar: MatSnackBar) {}

  public show(text: string, buttonText?: string): void {
    this.snackbar.open(text, buttonText, {
      duration: 3000,
      horizontalPosition: 'end',
      panelClass: ['snackbar'],
    });
  }

  public showError(text: string): void {
    if (!text) {
      text = 'Error';
    }
    this.snackbar.open('✖   ' + text, undefined, {
      duration: 3000,
      horizontalPosition: 'end',
      panelClass: ['snackbar-error'],
    });
  }

  public showSuccess(text: string): void {
    if (!text) {
      text = 'Success';
    }
    this.snackbar.open('✓   ' + text, undefined, {
      duration: 3000,
      horizontalPosition: 'end',
      panelClass: ['snackbar-success'],
    });
  }

  public showWarning(text: string): void {
    if (!text) {
      text = 'Warning';
    }
    this.snackbar.open('⚠   ' + text, undefined, {
      duration: 3000,
      horizontalPosition: 'end',
      panelClass: ['snackbar-warning'],
    });
  }
}
